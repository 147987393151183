html,body{
    background:#ddd
}
*{
    font-family: 'Roboto';
}

input{
    background:#fff !important;
}
